import './Section.css';
import React from 'react';
import { InView } from 'react-intersection-observer';

function Section({ playerControl, index }) {
  return (
    <InView
      threshold={0.6}
      initialInView={true}
      onChange={(inView) => playerControl(inView, index)}
    >
      {({ ref }) => <div ref={ref} className='section' idx={index}></div>}
    </InView>
  );
}

export default Section;
