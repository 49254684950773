import Modal from 'react-modal';
import React from 'react';
import background from './img/titles/tides-chapters-bg.jpg';
import './Title.css';

function Title({ closeModal, titleImg, isOpen }) {
  const customStyles = {
    content: {
      border: 'none',
      padding: '0px',
      borderRadius: 'none',
      background: `url(${background}) no-repeat center center fixed`,
      width: '100vw',
      height: '100vh',
      inset: 'initial',
      display: 'flex',
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
    },
    overlay: {
      background: `url(${background}) no-repeat center center fixed`,
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel='title'
      ariaHideApp={false}
      closeTimeoutMS={3000}
    >
      <button onClick={closeModal}>
        <img className='title-image' src={titleImg} alt='' />
      </button>
    </Modal>
  );
}

export default Title;
