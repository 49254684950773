import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Page from './Page';
import ScrollToTop from './ScrollToTop';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import PageFour from './PageFour';
import Intro from './Intro';

function App() {
  return (
    <main className='main'>
      <Router>
        <ScrollToTop />
        <Route path='/chapter-one' component={Page} />
        <Route path='/chapter-two' component={PageTwo} />
        <Route path='/chapter-three' component={PageThree} />
        <Route path='/chapter-four' component={PageFour} />
        <Route exact path='/' component={Intro} />
      </Router>
    </main>
  );
}

export default App;
