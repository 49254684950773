import React from 'react';
import { Link } from 'react-router-dom';
import './Intro.css';
import Logo from './img/titles/tides-logo.png';

export default function Intro() {
  return (
    <div className='intro'>
      <div className='center'>
        <div className='left'>
          <Link className='logo' to='/chapter-one'>
            <img src={Logo} alt='' />
          </Link>
        </div>
        <div className='right'>
          <div className='author'>
            <p>a sound essay in four chapters by</p>
            <p> Pedro Oliveira</p>
          </div>
          <div className='details'>
            <p>
              produced with the support of Akademie Schloss Solitude <br /> and
              ZKM Karlsruhe as winner of the HASH Award 2020
            </p>

            <p>
              all sounds recorded at EMS Stockholm, Summer 2019 <br />
              mixed and dubbed in Berlin, Fall 2021 <br />
              headphones recommended
            </p>

            <p>
              developed by{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/binauralfusion'
              >
                Berrak Nil Boya
              </a>{' '}
            </p>

            <p>2020–2022</p>
          </div>
        </div>
      </div>
    </div>
  );
}
