import React, { Component } from 'react';
import './Page.css';
import * as Tone from 'tone';
import Section from './Section';
import eg1 from './sound/ch2/EG_Snappy-1.wav';
import eg2 from './sound/ch2/EG_Snappy-2.wav';
import eg3 from './sound/ch2/EG_Snappy-3.wav';
import eg4 from './sound/ch2/EG_Snappy-4.wav';
import ga1 from './sound/ch2/GA_Snappy-1.wav';
import ga2 from './sound/ch2/GA_Snappy-2.wav';
import ga3 from './sound/ch2/GA_Snappy-3.wav';
import ga4 from './sound/ch2/GA_Snappy-4.wav';
import { Link } from 'react-router-dom';
import imgOne from './img/gifs/Ch2a.gif';
import imgTwo from './img/gifs/Ch2b.gif';
import imgThree from './img/gifs/Ch2c.gif';
import imgFour from './img/gifs/Ch2d.gif';
import titleOne from './img/titles/ch2-title.png';

import Modal from 'react-modal';
import Title from './Title';

let player1, player2, player3, player4, player5, player6, player7, player8;
let filter1, filter2, filter3, filter4, filter5, filter6, filter7, filter8;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    background: 'black',
    borderRadius: 'none',
  },
  overlay: {
    background: 'transparent',
  },
};

const left = [
  {
    frequency: 40,
    pan: 0,
  },
  {
    frequency: 161,
    pan: -0.12,
  },
  {
    frequency: 404,
    pan: -0.24,
  },
  {
    frequency: 867,
    pan: -0.36,
  },
  {
    frequency: 2022,
    pan: -0.48,
  },
  {
    frequency: 3393,
    pan: -0.6,
  },
  {
    frequency: 5526,
    pan: -0.72,
  },
  {
    frequency: 7743,
    pan: -0.84,
  },
];

const right = [
  {
    frequency: 200,
    pan: 0.06,
  },
  {
    frequency: 693,
    pan: 0.18,
  },
  {
    frequency: 1000,
    pan: 0.3,
  },
  {
    frequency: 3000,
    pan: 0.42,
  },
  {
    frequency: 4109,
    pan: 0.54,
  },
  {
    frequency: 6500,
    pan: 0.66,
  },
  {
    frequency: 6500,
    pan: 0.66,
  },
  {
    frequency: 12000,
    pan: 0.78,
  },
];

class PageTwo extends Component {
  state = {
    isReady: false,
    reached: false,
    modalIsOpen: true,
    background: imgOne,
    active: 1,
    showLink: false,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.getAudio();
  };

  getAudio = async () => {
    await Tone.start();
    this.buildPlayers();
    Tone.Transport.start();
  };

  getRandomItem = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
  };

  createPanFilterLeft = () => {
    let { frequency, pan } = this.getRandomItem(left);
    let panner = new Tone.Panner(pan);
    let filter = new Tone.Filter({
      frequency,
      type: 'bandpass',
      Q: 0.5,
      rolloff: -12,
    });
    return { panner, filter };
  };

  createPanFilterRight = () => {
    let { frequency, pan } = this.getRandomItem(right);
    let panner = new Tone.Panner(pan);
    let filter = new Tone.Filter({
      frequency,
      type: 'bandpass',
      Q: 0.5,
      rolloff: -12,
    });
    return { panner, filter };
  };

  buildPlayers = () => {
    filter1 = this.createPanFilterLeft();
    filter2 = this.createPanFilterRight();
    filter3 = this.createPanFilterLeft();
    filter4 = this.createPanFilterRight();
    filter5 = this.createPanFilterLeft();
    filter6 = this.createPanFilterRight();
    filter7 = this.createPanFilterLeft();
    filter8 = this.createPanFilterRight();

    player1 = new Tone.Player({
      url: eg1,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter1.panner, filter1.filter, Tone.Destination);

    player2 = new Tone.Player({
      url: ga1,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter2.panner, filter2.filter, Tone.Destination);

    player3 = new Tone.Player({
      url: eg2,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter3.panner, filter3.filter, Tone.Destination);

    player4 = new Tone.Player({
      url: ga2,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter4.panner, filter4.filter, Tone.Destination);

    player5 = new Tone.Player({
      url: eg3,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter5.panner, filter5.filter, Tone.Destination);

    player6 = new Tone.Player({
      url: ga3,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter6.panner, filter6.filter, Tone.Destination);

    player7 = new Tone.Player({
      url: eg4,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter7.panner, filter7.filter, Tone.Destination);

    player8 = new Tone.Player({
      url: ga4,
      loop: true,
      volume: 0,
      fadeIn: 2.5,
      fadeOut: 3.5,
    }).chain(filter8.panner, filter8.filter, Tone.Destination);

    Tone.loaded().then(() => {
      this.setState({ isReady: true });
    });

    Tone.Transport.scheduleRepeat((time) => {
      let adjustQBy = 0.5;
      let now = Tone.now();

      if (filter1.filter.Q.value === 15) {
        this.setState({ reached: true });
      }
      if (filter1.filter.Q.value === 0.5) {
        this.setState({ reached: false });
      }
      if (this.state.reached === false) {
        filter1.filter.Q.setValueAtTime(
          filter1.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter2.filter.Q.setValueAtTime(
          filter2.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter3.filter.Q.setValueAtTime(
          filter3.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter4.filter.Q.setValueAtTime(
          filter4.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter5.filter.Q.setValueAtTime(
          filter5.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter6.filter.Q.setValueAtTime(
          filter6.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter7.filter.Q.setValueAtTime(
          filter5.filter.Q.value + adjustQBy,
          now + 0.2
        );
        filter8.filter.Q.setValueAtTime(
          filter6.filter.Q.value + adjustQBy,
          now + 0.2
        );
      }
      if (this.state.reached === true && filter1.filter.Q.value >= 0.5) {
        filter1.filter.Q.setValueAtTime(
          filter1.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter2.filter.Q.setValueAtTime(
          filter2.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter3.filter.Q.setValueAtTime(
          filter3.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter4.filter.Q.setValueAtTime(
          filter4.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter5.filter.Q.setValueAtTime(
          filter5.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter6.filter.Q.setValueAtTime(
          filter6.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter7.filter.Q.setValueAtTime(
          filter5.filter.Q.value - adjustQBy,
          now + 0.2
        );
        filter8.filter.Q.setValueAtTime(
          filter6.filter.Q.value - adjustQBy,
          now + 0.2
        );
      }
    }, '8n');
  };

  componentWillUnmount() {
    if (
      Tone.context.state === 'running' &&
      this.state.isReady &&
      this.props.location.pathname === '/chapter-two'
    ) {
      player1.stop();
      player2.stop();
      player3.stop();
      player4.stop();
      player5.stop();
      player6.stop();
      player7.stop();
      player8.stop();
    }
  }

  playerControl = (val, idx) => {
    if (
      this.state.isReady === true &&
      this.props.location.pathname === '/chapter-two'
    ) {
      if (val === true && idx === 2) {
        this.setState({ active: 2 });
        player3.start();
        player4.start();

        player1.stop();
        player2.stop();
        player5.stop();
        player6.stop();
        player7.stop();
        player8.stop();
      }

      if (val === true && idx === 3) {
        this.setState({ active: 3, showLink: false });
        player5.start();
        player6.start();

        player1.stop();
        player2.stop();
        player3.stop();
        player4.stop();
        player7.stop();
        player8.stop();
      }

      if (val === true && idx === 4) {
        this.setState({ active: 4, showLink: true });
        player7.start();
        player8.start();

        player1.stop();
        player2.stop();
        player3.stop();
        player4.stop();
        player5.stop();
        player6.stop();
      }

      if (val === true && idx === 1) {
        this.setState({ active: 1 });
        player1.start();
        player2.start();

        player3.stop();
        player4.stop();
        player5.stop();
        player6.stop();
        player7.stop();
        player8.stop();
      }
    }
  };

  render() {
    return (
      <div className='page'>
        <Title
          closeModal={this.closeModal}
          titleImg={titleOne}
          isOpen={this.state.modalIsOpen}
        />
        {this.state.isReady ? (
          <>
            <Modal
              isOpen={this.state.active === 1}
              style={customStyles}
              contentLabel='section one'
              ariaHideApp={false}
              closeTimeoutMS={2000}
            >
              <img className='background' src={imgOne} alt='' index={1} />
            </Modal>
            <Modal
              isOpen={this.state.active === 2}
              style={customStyles}
              contentLabel='section two'
              ariaHideApp={false}
              closeTimeoutMS={2000}
            >
              <img className='background' src={imgTwo} alt='' index={2} />
            </Modal>
            <Modal
              isOpen={this.state.active === 3}
              style={customStyles}
              contentLabel='section three'
              ariaHideApp={false}
              closeTimeoutMS={2000}
            >
              <img className='background' src={imgThree} alt='' index={3} />
            </Modal>
            <Modal
              isOpen={this.state.active === 4}
              style={customStyles}
              contentLabel='section four'
              ariaHideApp={false}
              closeTimeoutMS={2000}
            >
              <img className='background' src={imgFour} alt='' index={4} />
            </Modal>

            {this.state.showLink && (
              <>
                <Link className='link-back fade-in' to='/chapter-one'>
                  previous chapter
                </Link>
                <Link className='link-fwd fade-in' to='/chapter-three'>
                  next chapter
                </Link>
              </>
            )}
            <div className='description fade-in'>
              <p>
                overtones excited by pulses
                <br /> snapping tightly, biting, tingling
              </p>

              <p>
                utterances stretched,
                <br />
                scattered in space
              </p>

              <p>speaking through the gates – astray</p>
            </div>
            <Section playerControl={this.playerControl} index={1} />
            <Section playerControl={this.playerControl} index={2} />
            <Section playerControl={this.playerControl} index={3} />
            <Section playerControl={this.playerControl} index={4} />
          </>
        ) : null}
      </div>
    );
  }
}

export default PageTwo;
